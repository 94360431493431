import { Scene } from "@/models/scene";
import { demoHouseScenes } from "./demo-house/scenes";
import { nbScenes } from "./WWM-NB/scenes";
import { lazy } from "react";
import { spaceInvadersScenes } from "./space-invaders/scenes";

/**
 * SetComponentDictionary is a dictionary object that maps string keys to lazy-loaded 3D set component imports.
 * The keys represent the names of the sets, and the values are the corresponding lazy-loaded component imports.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */
export const SetComponentDictionary: Record<string, any> = {
  DemoHouse: lazy(() => import("./demo-house/index")),
  WWMNewBalance: lazy(() => import("./WWM-NB/index")),
  SpaceInvaders: lazy(() => import("./space-invaders/index")),
};

/**
 * Represents a dictionary of scenes.
 * The keys represent the names of the sets, and the values are the corresponding Scenes array for the set.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */
export const ScenesDictionary: Record<string, Scene[]> = {
  DemoHouse: demoHouseScenes,
  WWMNewBalance: nbScenes,
  SpaceInvaders: spaceInvadersScenes,
};

/**
 * Represents a dictionary mapping virtual object names to their corresponding JSX elements.
 * The keys represent the names of the sets, and the values are the corresponding Virtual Objects Model Mapping for the set.
 * The name of the set is defined in the set object in the experience context, which is populated by the database.
 */

/**
 * Lazy loads the Virtual Object Mapping for the specified set name.
 * @param setName
 * @returns
 */
export const LazyStaticModels = async (setName: string) => {
  switch (setName) {
    case "DemoHouse":
      return await import("./demo-house/shared/DemoHouseStaticModelMapping");
    case "WWMNewBalance":
      return await import("./WWM-NB/shared/NBStaticModelMapping");
    case "SpaceInvaders":
      return await import(
        "./space-invaders/shared/SpaceInvadersStaticModelMapping"
      );
    default:
      throw new Error(`Unknown set name: ${setName}`);
  }
};

/**
 * Lazy loads the Dynamic Virtual Object Mapping for the specified set name.
 * @param setName
 * @returns
 */
export const LazyDynamicModels = async (setName: string) => {
  switch (setName) {
    case "DemoHouse":
      return await import("./demo-house/shared/DemoHouseDynamicModelMapping");
    case "WWMNewBalance":
      return await import("./WWM-NB/shared/NBDynamicModelMapping");
    case "SpaceInvaders":
      return await import(
        "./space-invaders/shared/SpaceInvadersDynamicModelMapping"
      );
    default:
      throw new Error(`Unknown set name: ${setName}`);
  }
};
