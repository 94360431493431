import { Canvas } from "@react-three/fiber";
import { Scene } from "@/models/scene";
import { Edit } from "lucide-react";
import { useEffect, useState } from "react";
import CameraRig from "./3d/CameraRig";
import { useInteractionContext } from "./context/InteractionContext";
import { ViewerDynamicModelWrapper } from "./3d/ViewerDynamicModelWrapper";
import { Experience } from "@/models/experience";
import { Set } from "@/models/set";
import {
  LazyDynamicModels,
  LazyStaticModels,
} from "@/components/sets/set-dictionary";
import { useExperienceContext } from "@/context/experience.context";
import { Environment } from "@react-three/drei";
import { ModelMap } from "@/components/sets/shared/ModelMapping";
import React from "react";

interface ViewerViewProps {
  set: Set;
  currentScene: Scene;
  experience: Experience;
  scenes: Scene[];
  currentCameraPosition: THREE.Vector3;
  currentTargetPosition: THREE.Vector3;
  setHasExperienceLoaded: (hasExperienceLoaded: boolean) => void;
  handleExperienceVirtualObjectTap: (obj: string) => void;
}

export const ViewerView = ({
  set,
  scenes,
  currentScene,
  setHasExperienceLoaded,
  handleExperienceVirtualObjectTap,
}: ViewerViewProps) => {
  const { realExperienceVirtualObjects } = useExperienceContext();
  const { setCurrentlySelected } = useInteractionContext();

  const [staticModelMapping, setStaticModelMapping] = useState<
    Record<string, ModelMap>
  >({});
  const [dynamicModelMapping, setDynamicModelMapping] = useState<
    Record<string, ModelMap>
  >({});

  useEffect(() => {
    LazyStaticModels(set.setComponentName).then((mapping) => {
      setStaticModelMapping(mapping.default);
    });

    LazyDynamicModels(set.setComponentName).then((mapping) => {
      setDynamicModelMapping(mapping.default);
    });
  }, [set]);

  return (
    <div
      style={{
        height: "100dvh",
        width: "100dvw",
        position: "relative",
      }}
    >
      <Canvas
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "black",
        }}
      >
        {/* Camera components */}
        <CameraRig
          setHasExperienceLoaded={setHasExperienceLoaded}
          currentScene={currentScene}
        />

        {/* Environment components */}
        <Environment files="/textures/Env.hdr" blur={0.07} />
        <directionalLight intensity={2} />
        <ambientLight position={[10, 10, 10]} />

        {/* Static models */}
        {staticModelMapping &&
          Object.entries(staticModelMapping).map(([id, object]) => {
            if (object.model) {
              return <group key={id}>{object.model}</group>;
            }
          })}

        {/* Dynamic models */}
        {scenes &&
          scenes.map(
            (scene) =>
              scene.objectPositions &&
              scene.objectPositions.map(
                (pos) =>
                  pos.virtualObjects &&
                  pos.virtualObjects.map((virtualObj) => {
                    const experienceVirtualObject =
                      realExperienceVirtualObjects.find(
                        (experienceVirtualObj) =>
                          experienceVirtualObj.virtualObjectId === virtualObj.id
                      );

                    if (
                      !experienceVirtualObject ||
                      (experienceVirtualObject &&
                        experienceVirtualObject.positionId !== pos.id) ||
                      !virtualObj.virtualObjectComponentName ||
                      !dynamicModelMapping
                    ) {
                      return null;
                    }

                    return (
                      experienceVirtualObject &&
                      virtualObj.virtualObjectComponentName &&
                      dynamicModelMapping && (
                        <ViewerDynamicModelWrapper
                          key={virtualObj.id}
                          obj={virtualObj}
                          currentSceneId={currentScene.id}
                          onClick={() => {
                            handleExperienceVirtualObjectTap(
                              experienceVirtualObject.id
                            );
                            setCurrentlySelected(experienceVirtualObject.id);
                          }}
                        >
                          {dynamicModelMapping[
                            virtualObj.virtualObjectComponentName
                          ] && (
                            <group key={virtualObj.id}>
                              {React.cloneElement(
                                dynamicModelMapping[
                                  virtualObj.virtualObjectComponentName
                                ].model,
                                {
                                  position: [
                                    pos.xPosition,
                                    pos.yPosition,
                                    pos.zPosition,
                                  ],
                                }
                              )}
                            </group>
                          )}
                        </ViewerDynamicModelWrapper>
                      )
                    );
                  })
              )
          )}
      </Canvas>
    </div>
  );
};

interface EditorViewTabProps {
  isProductEditorOpen: boolean;
  setIsProductEditorOpen: (isProductEditorOpen: boolean) => void;
}

export const EditorViewTab = ({
  isProductEditorOpen,
  setIsProductEditorOpen,
}: EditorViewTabProps) => {
  return (
    <div
      onClick={() => setIsProductEditorOpen(!isProductEditorOpen)}
      className="text-white select-none self-center m-4 flex flex-row cursor-pointer"
    >
      <Edit className="mr-2" />
      <p>Edit Products / Assets</p>
    </div>
  );
};
