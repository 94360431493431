import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useExperienceContext } from "@/context/experience.context";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import ProductInformation from "./ProductInformation";
import { Card, CardContent } from "../../../components/ui/card";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Badge } from "../../../components/ui/badge";
import { ScrollArea } from "../../../components/ui/scroll-area";
import {
  ChatBubble,
  ChatBubbleMessage,
} from "@/components/ui/chat/chat-bubble";
import { ChatInput } from "@/components/ui/chat/chat-input";

import { ChatMessageList } from "@/components/ui/chat/chat-message-list";
import { Mic, Send, StarsIcon, User2 } from "lucide-react";
import { Avatar, AvatarFallback } from "../../../components/ui/avatar";
import ChatMessageService from "@/services/chat-message.service";
import { combineLatest } from "rxjs";
import { useTenantContext } from "@/context/tenant.context";
import { useAuth } from "@/context/auth.context";
import { ChatMessage } from "@/models/chat-message";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
// import { SpeechRecognition } from "@/types/speech-recognition";

const formSchema = z.object({
  message: z.string().min(1, "Message is required"),
});

// let recognition: SpeechRecognition;

const MenuDrawer: FC<{
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  // REFERENCE:
  const [snap, setSnap] = useState<number | string | null>(1);

  // STATE:
  // const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
  //   null
  // );
  const { authUser } = useAuth();
  const { experience, realExperienceVirtualObjects, listProductVariants } =
    useExperienceContext();
  const [
    savedListOfExperienceVirtualObjects,
    setSavedListOfExperienceVirtualObjects,
  ] = useState<ExperienceVirtualObject[]>([]);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const { aiSessionId, setAiSessionId } = useExperienceContext();
  // const [isRecording, setIsRecording] = useState(false);

  // /**
  //  * Start Voice Recording, and submit the form after each phrase
  //  * @returns
  //  */
  // const startVoiceRecording = () => {
  //   if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
  //     alert("Speech recognition is not supported in your browser.");
  //     return;
  //   }

  //   const SpeechRecognition =
  //     window.SpeechRecognition || window.webkitSpeechRecognition;
  //   recognition = new SpeechRecognition();

  //   recognition.continuous = true; // Keep listening for phrases
  //   recognition.interimResults = false;
  //   recognition.lang = "en-US";

  //   recognition.onstart = () => {
  //     setIsRecording(true);
  //   };

  //   recognition.onresult = (event) => {
  //     // Get the last transcript and submit the form with it as the message
  //     const transcript = event.results[event.results.length - 1][0].transcript;
  //     submitChat(transcript, true); // Submit the form
  //   };

  //   recognition.onerror = (event) => {
  //     console.error(event.error);
  //     setIsRecording(false);
  //   };

  //   recognition.onend = () => {
  //     if (isRecording) {
  //       recognition.start(); // Restart recognition to keep listening
  //     }
  //   };

  //   recognition.start();
  // };

  /**
   * Stop Voice Recording
   */
  // const stopVoiceRecording = () => {
  //   recognition.stop();
  //   setIsRecording(false);
  // };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  useEffect(() => {
    const newListOfExperienceVirtualObjects: ExperienceVirtualObject[] = [];
    for (const listProductVariant of listProductVariants) {
      const matchingExperienceVirtualObjects =
        realExperienceVirtualObjects.find(
          (experienceVirtualObject) =>
            experienceVirtualObject.productId === listProductVariant.productId
        );
      if (matchingExperienceVirtualObjects) {
        newListOfExperienceVirtualObjects.push(
          matchingExperienceVirtualObjects
        );
      }
    }
    setSavedListOfExperienceVirtualObjects(newListOfExperienceVirtualObjects);
  }, [listProductVariants, realExperienceVirtualObjects]);

  /**
   * Subscribe to chat messages
   */
  useEffect(() => {
    if (experience && experience.isAiChatEnabled) {
      const subscription = combineLatest([
        ChatMessageService.getAllByAiSession(
          experience.tenantId,
          experience.id,
          authUser.uid,
          aiSessionId
        ),
      ]).subscribe({
        next: ([chatMessages]) => {
          setChatMessages(chatMessages);
        },
        error: (err) => {
          alert(err.message);
          throw err;
        },
        complete: () => console.info("complete"),
      });

      return () => subscription.unsubscribe();
    }
  }, [experience, aiSessionId, authUser.uid]);

  /**
   * Scroll to bottom of chat window after message changes
   */
  useEffect(() => {
    // scroll to the bottom
    scrollChatToBottom();
  }, [chatMessages]);

  const chatScrollAreaRef = useRef<HTMLDivElement>(null);

  const scrollChatToBottom = () => {
    if (chatScrollAreaRef.current) {
      chatScrollAreaRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await submitChat(values.message, false);
      form.setValue("message", "");
    } catch (err) {
      alert(err.message);
      throw err;
    }
  }

  async function submitChat(message: string, audioResponse: boolean) {
    try {
      const response = await ChatMessageService.saveOne(
        experience.brandId,
        experience.id,
        aiSessionId,
        message
      );
      if (response.aiSessionId) setAiSessionId(response.aiSessionId);
      // if (response.audioResponse && audioResponse) {
      //   // Stop any currently playing audio
      //   if (currentAudio) {
      //     currentAudio.pause();
      //     currentAudio.currentTime = 0;
      //   }
      //   const audioSrc = `data:audio/mp3;base64,${response.audioResponse}`;
      //   const audio = new Audio(audioSrc);
      //   setCurrentAudio(audio);
      //   audio.play();
      // }
    } catch (err) {
      throw err;
    }
  }

  const closeModal = () => {
    // Stop voice recording if it is active
    // recognition?.stop();
    // // Stop any playing audio
    // if (currentAudio) {
    //   currentAudio.pause();
    //   currentAudio.currentTime = 0;
    //   setCurrentAudio(null);
    // }
  };

  return (
    <Drawer
      open={isOpen}
      onOpenChange={setIsOpen}
      onClose={() => closeModal()}
      snapPoints={[1]}
      activeSnapPoint={snap}
      setActiveSnapPoint={setSnap}
      handleOnly={true}
    >
      <DrawerPortal>
        <DrawerContent className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[95%] mx-[-1px]">
          <div className="absolute -top-3 right-3 z-10">
            <Button
              size="icon"
              onClick={() => setIsOpen(false)}
              variant="default"
            >
              <HiOutlineX className="w-6 h-6" />
            </Button>
          </div>
          <DrawerHeader className="hidden">
            <DrawerTitle className="hidden">Menu</DrawerTitle>
            <DrawerDescription className="hidden">
              Menu for the experience
            </DrawerDescription>
          </DrawerHeader>
          <Tabs defaultValue="products" className="w-full pl-2 pr-2">
            <TabsList className=" w-full">
              <TabsTrigger value="products">All Products</TabsTrigger>
              <TabsTrigger value="saved">
                Saved{" "}
                {savedListOfExperienceVirtualObjects.length > 0 && (
                  <Badge variant="default" className="ml-1">
                    {savedListOfExperienceVirtualObjects.length}
                  </Badge>
                )}
              </TabsTrigger>
              {experience.isAiChatEnabled && (
                <TabsTrigger value="chat" onClick={() => scrollChatToBottom()}>
                  Chat
                </TabsTrigger>
              )}
            </TabsList>
            <TabsContent value="chat">
              <ScrollArea className="h-screen">
                <ChatMessageList>
                  {/* Example messages */}
                  <ChatBubble layout="default" variant="received">
                    <Avatar>
                      <AvatarFallback>
                        <StarsIcon />
                      </AvatarFallback>
                    </Avatar>
                    <ChatBubbleMessage>
                      {experience.aiChatIntroduction
                        ? experience.aiChatIntroduction
                        : "Hello, how may I help you?"}
                    </ChatBubbleMessage>
                  </ChatBubble>
                  {chatMessages.map((chatMessage, index) => (
                    <Fragment key={index}>
                      <ChatBubble layout="default" variant="sent">
                        <Avatar>
                          <AvatarFallback>
                            <User2 />
                          </AvatarFallback>
                        </Avatar>
                        <ChatBubbleMessage>
                          {chatMessage.request}
                        </ChatBubbleMessage>
                      </ChatBubble>
                      <ChatBubble layout="default" variant="received">
                        <Avatar>
                          <AvatarFallback>
                            <StarsIcon />
                          </AvatarFallback>
                        </Avatar>
                        <ChatBubbleMessage>
                          {chatMessage.response ? (
                            chatMessage.response.map(
                              (response, responseIndex) => (
                                <span key={responseIndex}>{response}</span>
                              )
                            )
                          ) : (
                            <span>
                              <span className="animate-bounce inline-block font-black">
                                .
                              </span>
                              <span className="animate-bounce inline-block delay-100 font-black">
                                .
                              </span>
                              <span className="animate-bounce inline-block delay-200 font-black">
                                .
                              </span>
                            </span>
                          )}
                        </ChatBubbleMessage>
                      </ChatBubble>
                      <span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              chatMessage.llmResponse?.custom?.candidates[0]
                                ?.groundingMetadata?.searchEntryPoint
                                ?.renderedContent || "",
                          }}
                        />
                      </span>
                    </Fragment>
                  ))}
                </ChatMessageList>
                <div className="h-80" /> {/* Spacer */}
                <div ref={chatScrollAreaRef} /> {/* Scroll to bottom */}
              </ScrollArea>
              <div className="p-4 border-t border-gray-200 fixed bottom-0 left-0 right-0 bg-white">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                      control={form.control}
                      name="message"
                      render={({ field }) => (
                        <ChatInput
                          // style={{ display: isRecording ? "none" : "block" }}
                          {...field}
                          placeholder="Type your question..."
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              form.handleSubmit(onSubmit)();
                            }
                          }}
                        />
                      )}
                    />
                    <div className="flex gap-2 mt-2">
                      {/* Speech-to-text button. Hide if browser does not support */}
                      {/* {(window.SpeechRecognition ||
                        window.webkitSpeechRecognition) && (
                        <Button
                          type="button"
                          variant={isRecording ? "destructive" : "outline"}
                          size="icon"
                          className="flex-none"
                          onClick={
                            isRecording
                              ? stopVoiceRecording
                              : startVoiceRecording
                          }
                        >
                          <Mic
                            className={`w-6 h-6 ${
                              isRecording ? "animate-pulse" : ""
                            }`}
                          />
                        </Button>
                      )} */}

                      {/* Submit button, but hide if isRecording */}
                      {/* {isRecording ? (
                        <p className="flex-1 text-xl p-1">Listening...</p>
                      ) : ( */}
                      <Button
                        variant="default"
                        className="flex-1"
                        size="icon"
                        type="submit"
                        disabled={form.formState.isSubmitting}
                      >
                        <Send className="w-6 h-6 mr-2" />
                        Send
                      </Button>
                      {/* )} */}
                    </div>
                  </form>
                </Form>
              </div>
            </TabsContent>
            <TabsContent value="saved">
              <ScrollArea className="h-screen">
                {savedListOfExperienceVirtualObjects.length === 0 && (
                  <p>No products are in your saved list.</p>
                )}
                {savedListOfExperienceVirtualObjects.map(
                  (experienceVirtualObject) => (
                    <Card key={experienceVirtualObject.id} className="p-2 mb-4">
                      <ProductInformation
                        experienceVirtualObject={experienceVirtualObject}
                        allowProductRemoval={true}
                      />
                    </Card>
                  )
                )}
                <div className="h-40" />
              </ScrollArea>
            </TabsContent>
            <TabsContent value="products">
              <ScrollArea className="h-screen">
                {realExperienceVirtualObjects.map((experienceVirtualObject) => (
                  <div key={experienceVirtualObject.id}>
                    {experienceVirtualObject.productId && (
                      <Card
                        key={experienceVirtualObject.id}
                        className="p-2 mb-4"
                      >
                        <ProductInformation
                          experienceVirtualObject={experienceVirtualObject}
                          allowProductRemoval={false}
                        />
                      </Card>
                    )}
                  </div>
                ))}
                <div className="h-40" />
              </ScrollArea>
            </TabsContent>
          </Tabs>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export default MenuDrawer;
