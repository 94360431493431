import "regenerator-runtime/runtime"; // used by react-speech-recognition
import { Button } from "@/components/ui/button";
import { AudioLinesIcon, Mic } from "lucide-react";
import { FC, useState } from "react";
import { useExperienceContext } from "@/context/experience.context";
import ChatMessageService from "@/services/chat-message.service";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaSpinner } from "react-icons/fa6";

const VoiceAiChatButton: FC = () => {
  const { experience } = useExperienceContext();
  const [isIntroPlayed, setIsIntroPlayed] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isGettingAudio, setIsGettingAudio] = useState(false);
  const { aiSessionId, setAiSessionId } = useExperienceContext();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition({
    commands: [],
  });

  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (err) {
      console.error("Microphone permission denied:", err);
      return false;
    }
  };

  const startVoiceRecording = async () => {
    try {
      if (!isIntroPlayed) {
        await getIntro();
        setIsIntroPlayed(true);
      }

      const hasPermission = await requestMicrophonePermission();
      if (!hasPermission) {
        alert("Microphone permission is required to use voice chat");
        return;
      }

      await SpeechRecognition.startListening({
        continuous: true,
      });
    } catch (err) {
      alert(err.message);
      console.error(err);
    }
  };

  const stopVoiceRecording = async () => {
    try {
      await SpeechRecognition.stopListening();
      if (transcript) {
        await submitChatMessage(transcript);
        resetTranscript();
      }
    } catch (err) {
      alert(err.message);
      console.error(err);
    }
  };

  async function submitChatMessage(message: string) {
    try {
      setIsGettingAudio(true);
      const response = await ChatMessageService.saveOne(
        experience.brandId,
        experience.id,
        aiSessionId,
        message
      );
      setIsGettingAudio(false);
      if (response.aiSessionId) setAiSessionId(response.aiSessionId);
      if (response.audioResponse) {
        setIsAudioPlaying(true);

        const audioSrc = `data:audio/mp3;base64,${response.audioResponse}`;
        const audio = new Audio(audioSrc);

        // setCurrentAudio(audio);
        await new Promise((resolve, reject) => {
          audio.addEventListener("ended", resolve);
          audio.addEventListener("error", reject);
          audio.play().catch(reject);
        });
        setIsAudioPlaying(false);
      }
    } catch (err) {
      alert(err.message);
      setIsAudioPlaying(false);
      setIsGettingAudio(false);
      throw err;
    }
  }

  /**
   * Get the introduction audio to let the user now the AI is listening and ready to respond
   */
  async function getIntro() {
    try {
      setIsGettingAudio(true);
      const response = await ChatMessageService.getIntroduction(
        experience.brandId,
        experience.id
      );
      setIsGettingAudio(false);
      if (response.audioResponse) {
        setIsAudioPlaying(true);
        const audioSrc = `data:audio/mp3;base64,${response.audioResponse}`;
        const audio = new Audio(audioSrc);

        // setCurrentAudio(audio);
        await new Promise((resolve, reject) => {
          audio.addEventListener("ended", resolve);
          audio.addEventListener("error", reject);
          audio.play().catch(reject);
        });
        setIsAudioPlaying(false);
      }
    } catch (err) {
      alert(err.message);
      throw err;
    }
  }

  // Check browser support
  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  // Check microphone access
  if (!isMicrophoneAvailable) {
    return (
      <Button
        type="button"
        variant="default"
        size="default"
        className="flex-none fixed bottom-1 left-1/2 transform -translate-x-1/2 z-10 pointer-events-auto w-[80%]"
        disabled
      >
        <Mic className="w-5 h-5 mr-2" /> Microphone access needed
      </Button>
    );
  }

  return (
    <Button
      type="button"
      variant={listening ? "outline" : "default"}
      size="default"
      className={`flex-none fixed bottom-1 left-1/2 transform -translate-x-1/2 z-10 pointer-events-auto w-[80%]`}
      onClick={
        isAudioPlaying || isGettingAudio
          ? undefined
          : listening
          ? stopVoiceRecording
          : startVoiceRecording
      }
      disabled={isAudioPlaying || isGettingAudio}
    >
      {(() => {
        switch (true) {
          case isGettingAudio:
            return (
              <>
                <FaSpinner className="w-5 h-5 mr-2 animate-spin" />
                Thinking...
              </>
            );
          case isAudioPlaying:
            return (
              <>
                <AudioLinesIcon className="w-5 h-5 mr-2" /> Speaking...
              </>
            );
          case listening:
            return (
              <>
                <div className="w-5 h-5 mr-2 rounded-full bg-red-600 animate-flash" />{" "}
                Listening. Tap when complete.
              </>
            );
          default:
            return (
              <>
                <Mic className="w-5 h-5 mr-2" /> Ask a question
              </>
            );
        }
      })()}
    </Button>
  );
};

export default VoiceAiChatButton;
