import { useAuth } from "@/context/auth.context";
import { TenantContext } from "@/context/tenant.context";
import { Brand } from "@/models/brand";
import { Experience } from "@/models/experience";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import { Placement } from "@/models/placement";
import { Scene } from "@/models/scene";
import { Store } from "@/models/store";
import { Event } from "@/models/event";
import { Tenant } from "@/models/tenant";
import { TestAccess } from "@/models/test-access";
import { VirtualObject } from "@/models/virtual-object";
import EventService from "@/services/event.service";
import ExperienceVirtualObjectService from "@/services/experience-virtual-object.service";
import ExperienceService from "@/services/experience.service";
import PlacementService from "@/services/placement.service";
import SetService from "@/services/set.service";
import StoreService from "@/services/store.service";
import TenantService from "@/services/tenant.service";
import { useEffect, useState, type FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { combineLatest, firstValueFrom, map, switchMap } from "rxjs";
import { BrandContext } from "@/context/brand.context";
import BrandService from "@/services/brand.service";
import { Set } from "@/models/set";
import ErrorAlert from "@/pages/manager/components/alerts/ErrorAlert";
import { ExperienceContext } from "@/context/experience.context";
import SpinnerCentered from "@/components/SpinnerCentered";
import { AnalyticsContext } from "@/context/analytics.context";
import GoogleAnalyticsService from "@/services/googleAnalytics.service";
import UserAccountActionService from "@/services/userAccountAction.service";
import { ProductVariant } from "@/models/product-variant";
import { Product } from "@/models/product";
import CartProductVariantService from "@/services/cart-product-variant.service";
import ListProductVariantService from "@/services/list-product-variant.service";
import { CartProductVariant } from "@/models/cart-product-variant";
import { ListProductVariant } from "@/models/list-product-variant";
import MenuDrawer from "@/pages/Viewer/components/MenuDrawer";
import BrowserDrawer from "@/pages/Viewer/components/BrowserDrawer";
import ProductDrawer from "@/pages/Viewer/components/ProductDrawer";
import SurveyDrawer from "@/pages/Viewer/components/SurveyDrawer";
import TestAccessService from "@/services/test-access.service";
import DataTransformationService from "@/services/data-transformation.service";
import { isMobile } from "react-device-detect";
import ThemeService from "@/services/theme.service";
import { Video } from "@/models/video";
import VideoService from "@/services/video.service";

const ViewerLayout: FC = () => {
  const params = useParams();
  const auth = useAuth();

  const [tenant, setTenant] = useState<Tenant>(undefined);
  const [brand, setBrand] = useState<Brand | undefined>(undefined);

  // used to determine if the system has logged the view of the experience
  const [loggedViewExperience, setLoggedViewExperience] =
    useState<boolean>(false);

  const [experience, setExperience] = useState<Experience>(undefined);
  const [stores, setStores] = useState<Store[]>(undefined);
  const [event, setEvent] = useState<Event>(undefined);
  const [cartProductVariants, setCartProductVariants] =
    useState<CartProductVariant[]>(undefined);
  const [listProductVariants, setListProductVariants] =
    useState<ListProductVariant[]>(undefined);
  const [placement, setPlacement] = useState<Placement>(undefined);
  const [experienceVirtualObjects, setExperienceVirtualObjects] =
    useState<ExperienceVirtualObject[]>(undefined);
  const [realExperienceVirtualObjects, setRealExperienceVirtualObjects] =
    useState<ExperienceVirtualObject[]>(undefined);
  const [set, setSet] = useState<Set>(undefined);
  const [video, setVideo] = useState<Video>(undefined);

  const [scenes, setScenes] = useState<Scene[]>(undefined);
  const [currentScene, setCurrentScene] = useState<Scene>(undefined);
  const [virtualObjects, setVirtualObjects] = useState<VirtualObject[]>([]);
  // used to determine if the experience can be viewed based on date restrictions or override code
  const [canViewExperience, setCanViewExperience] = useState<boolean>();
  //used for ai chat session
  const [aiSessionId, setAiSessionId] = useState<string | null>(null);

  const [error, setError] = useState<Error | undefined>(undefined);

  // Menu Drawer States
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState<boolean>(false);

  // Browser Modal States
  const [browserModalIsOpen, setBrowserModalIsOpen] = useState<boolean>(false);
  const [browserIsOpen, setBrowserIsOpen] = useState<boolean>(false);
  const [browserUrl, setBrowserUrl] = useState<string>();

  // States for the product drawer
  const [
    productDrawerExperienceVirtualObject,
    setProductDrawerExperienceVirtualObject,
  ] = useState<ExperienceVirtualObject>(undefined);
  const [isProductDrawerOpen, setIsProductDrawerOpen] =
    useState<boolean>(false);

  // States for the survey drawer
  const [
    surveyDrawerExperienceVirtualObject,
    setSurveyDrawerExperienceVirtualObject,
  ] = useState<ExperienceVirtualObject>(undefined);
  const [isSurveyDrawerOpen, setIsSurveyDrawerOpen] = useState<boolean>(false);

  /**
   * Opens the product drawer with the given experience virtual object
   * @param experienceVirtualObject
   */
  function handleProductDrawerOpen(
    experienceVirtualObject: ExperienceVirtualObject
  ) {
    setProductDrawerExperienceVirtualObject(experienceVirtualObject);
    setIsProductDrawerOpen(true);
  }

  /**
   * Opens the survey drawer with the given experience virtual object
   * @param experienceVirtualObject
   */
  function handleSurveyDrawerOpen(
    experienceVirtualObject: ExperienceVirtualObject
  ) {
    setSurveyDrawerExperienceVirtualObject(experienceVirtualObject);
    setIsSurveyDrawerOpen(true);
  }

  // Get Tenant from Auth
  useEffect(() => {
    if (!auth.isLoading && auth.authUser) {
      // fetch brand from API
      const subscription = combineLatest([
        TenantService.getOne(auth.authUser.tenantId),
      ]).subscribe(([tenant]) => {
        setTenant(tenant);
      });
      return () => subscription.unsubscribe();
    } else {
      return;
    }
  }, [auth]);

  useEffect(() => {
    if (!tenant || !auth.authUser || auth.isLoading || !params.placementId)
      return;

    // fetch experience and supporting data from API
    const subscription = combineLatest([
      PlacementService.getOne(params.placementId),
    ])
      .pipe(
        switchMap(([placement]) => {
          return combineLatest([
            ExperienceService.getOne(placement.experienceId),
          ]).pipe(
            switchMap(([experience]) => {
              return combineLatest([
                BrandService.getOne(experience.brandId),
                StoreService.getAllByBrand(tenant.id, experience.brandId),
                EventService.getOne(experience.eventId),
                SetService.getOne(experience.setId),
                VideoService.getOne(experience.videoId),
                ExperienceVirtualObjectService.getAllByExperience(
                  tenant.id,
                  experience.brandId,
                  experience.id
                ),
                CartProductVariantService.getCartProductVariantsByUserAndEvent(
                  tenant.id,
                  auth.authUser.uid,
                  experience.eventId
                ),
                ListProductVariantService.getListProductVariantsByUserAndEvent(
                  tenant.id,
                  auth.authUser.uid,
                  experience.eventId
                ),
              ]).pipe(
                map(
                  ([
                    brand,
                    stores,
                    event,
                    set,
                    video,
                    experienceVirtualObjects,
                    cartProductVariants,
                    listProductVariants,
                  ]) => ({
                    experience,
                    placement,
                    brand,
                    stores,
                    event,
                    set,
                    video,
                    experienceVirtualObjects,
                    cartProductVariants,
                    listProductVariants,
                  })
                )
              );
            })
          );
        })
      )
      .subscribe({
        next: async ({
          experience,
          placement,
          brand,
          stores,
          event,
          set,
          video,
          experienceVirtualObjects,
          cartProductVariants,
          listProductVariants,
        }) => {
          try {
            if (!experience) throw new Error("Experience not found.");
            setBrand(brand);
            setPlacement(placement);
            setExperience(experience);
            setStores(stores);
            setEvent(event);
            setCartProductVariants(cartProductVariants);
            setListProductVariants(listProductVariants);
            setExperienceVirtualObjects([]); //TODO: This is a temporary override implemented by @rajin-fpd, but can't remain like this. Must use actual experienceVirtualObjects from database
            setRealExperienceVirtualObjects(experienceVirtualObjects); //TODO: This is temporary so we can actually use data from the database
            setSet(set);
            setVideo(video);

            // Set Theme
            ThemeService.updateThemeVariables(experience.theme);

            // Determine if the experience can be viewed
            if (params.testAccessId) {
              // check if test access code is valid
              const testAccess: TestAccess = await firstValueFrom(
                TestAccessService.getOne(params.testAccessId)
              );

              if (
                testAccess &&
                testAccess.testAccessStartDate &&
                DataTransformationService.convertValueToDate(
                  testAccess.testAccessStartDate
                ) < Date.now() &&
                testAccess.testAccessEndDate &&
                DataTransformationService.convertValueToDate(
                  testAccess.testAccessEndDate
                ) > Date.now()
              ) {
                // current date is between start and end date
                setCanViewExperience(true);
              } else {
                setCanViewExperience(false);
              }
            } else if (
              experience &&
              experience.experienceStartDate &&
              DataTransformationService.convertValueToDate(
                experience.experienceStartDate
              ) < Date.now() &&
              experience.experienceEndDate &&
              DataTransformationService.convertValueToDate(
                experience.experienceEndDate
              ) > Date.now()
            ) {
              // current date is between start and end date
              setCanViewExperience(true);
            } else {
              setCanViewExperience(false);
            }
          } catch (err) {
            console.error(err);
            throw error;
          }
        },
        error: (error) => {
          console.error(error);
          setError(error);
        },
      });

    return () => subscription.unsubscribe();
  }, [params.placementId, tenant, auth]);

  /**
   * Log User View Experience
   */
  useEffect(() => {
    if (!loggedViewExperience && auth.authUser && experience && placement) {
      setLoggedViewExperience(true);
      UserAccountActionService.logViewExperience(
        experience,
        placement.id,
        auth.authUser
      ).catch((err) => {
        console.error(err);
      });
    }
    return;
  }, [loggedViewExperience, experience, placement, auth.authUser]);

  const handleExperienceVirtualObjectTap = (
    experienceVirtualObjectId: string
  ) => {
    if (!experienceVirtualObjectId) return;

    console.log("handleExperienceVirtualObjectTap", experienceVirtualObjectId);

    //get experience virtual object
    const experienceVirtualObject: ExperienceVirtualObject =
      realExperienceVirtualObjects.find(
        (evo) => evo.id === experienceVirtualObjectId
      );

    if (!experienceVirtualObject) {
      throw new Error(`Object ID ${experienceVirtualObjectId} not found.`);
    }

    if (experienceVirtualObject.productId) {
      handleProductDrawerOpen(experienceVirtualObject);
    } else if (experienceVirtualObject.surveyId) {
      handleSurveyDrawerOpen(experienceVirtualObject);
    }

    return;
  };

  // Log User Account Action and Google Analytics when the video was watched and ends
  const logViewWelcomeScreen = () => {
    if (experience && auth.authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "view_welcome_screen",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "view_welcome_screen",
        experience,
        auth.authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logViewWelcomeScreen."
      );
    }
  };

  const logClickButtonToEnter = () => {
    if (experience && auth.authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "click_button_to_enter",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "click_button_to_enter",
        experience,
        auth.authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logClickButtonToEnter."
      );
    }
  };

  // Log User Account Action and Google Analytics when the video starts
  const logHostVideoHasStarted = () => {
    if (experience && auth.authUser) {
      // Log Google Analytics
      GoogleAnalyticsService.logGeneralInteraction(
        "host_video_started",
        experience
      );

      // Log User Account Action
      UserAccountActionService.logGeneralInteraction(
        "host_video_started",
        experience,
        auth.authUser
      ).catch(console.error);
    } else {
      console.error(
        "Experience or Auth User not defined to logHostVideoHasStarted."
      );
    }
  };

  // Provides a browser modal to the user
  const openBrowser = (
    store: Store,
    experienceVirtualObject: ExperienceVirtualObject,
    product: Product,
    productVariant: ProductVariant,
    url: string,
    urlSupportsIFrameEmbed: boolean
  ) => {
    // Log Google Analytics
    GoogleAnalyticsService.logItemInteraction(
      "view_item_website",
      experience,
      placement.id,
      store,
      experienceVirtualObject,
      product,
      productVariant
    );

    // Log User Account Action
    UserAccountActionService.logItemInteraction(
      "view_item_website",
      experience,
      placement.id,
      store,
      experienceVirtualObject,
      product,
      productVariant,
      auth.authUser
    ).catch((err) => console.error(err));

    setBrowserUrl(url);

    if (urlSupportsIFrameEmbed) {
      setBrowserModalIsOpen(true);
    } else {
      window.open(url, "_blank");
      // TODO: Disabled browser opening alert for now.
      // setBrowserIsOpen(true);
    }
  };

  /**
   * Show default screen if no placementId is provided
   */
  if (!params.placementId) {
    return (
      <div className="h-screen flex items-center justify-center">
        <h1>Drawbridge Labs</h1>
      </div>
    );
  }

  /**
   * Show loading spinner if tenant, brand, experience, or set is not defined
   */
  if (!tenant || !brand || !experience || !set) {
    return <SpinnerCentered />;
  }

  /**
   * Initialize the viewer with it's components and pass the tenant, brand, experience contexts to the children components.
   */
  return (
    <>
      <TenantContext.Provider value={tenant}>
        <BrandContext.Provider value={brand}>
          <ExperienceContext.Provider
            value={{
              setIsMenuDrawerOpen,
              handleExperienceVirtualObjectTap,
              openBrowser,
              experience,
              stores,
              event,
              cartProductVariants,
              listProductVariants,
              placement,
              experienceVirtualObjects,
              realExperienceVirtualObjects,
              setExperienceVirtualObjects,
              set,
              video,
              setScenes,
              scenes,
              setCurrentScene,
              currentScene,
              virtualObjects,
              setVirtualObjects,
              canViewExperience,
              isMobile,
              aiSessionId,
              setAiSessionId,
            }}
          >
            <AnalyticsContext.Provider
              value={{
                logViewWelcomeScreen,
                logHostVideoHasStarted,
                logClickButtonToEnter,
              }}
            >
              <Outlet />
              <MenuDrawer
                isOpen={isMenuDrawerOpen}
                setIsOpen={setIsMenuDrawerOpen}
              />
              {/* <BrowserDrawer
                  isOpen={isBrowserDrawerOpen}
                  setIsOpen={setIsBrowserDrawerOpen}
                  browserUrl="https://google.com"
                /> */}
              <ProductDrawer
                isOpen={isProductDrawerOpen}
                setIsOpen={setIsProductDrawerOpen}
                experienceVirtualObject={productDrawerExperienceVirtualObject}
              />
              <SurveyDrawer
                isOpen={isSurveyDrawerOpen}
                setIsOpen={setIsSurveyDrawerOpen}
                experienceVirtualObject={surveyDrawerExperienceVirtualObject}
              />
            </AnalyticsContext.Provider>
          </ExperienceContext.Provider>
        </BrandContext.Provider>
      </TenantContext.Provider>
      <ErrorAlert error={error} setError={setError} />
    </>
  );
};

export default ViewerLayout;
