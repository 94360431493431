import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerPortal,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useAuth } from "@/context/auth.context";
import { useExperienceContext } from "@/context/experience.context";
import { Product } from "@/models/product";
import { ProductVariant } from "@/models/product-variant";
import { Store } from "@/models/store";
import GoogleAnalyticsService from "@/services/googleAnalytics.service";
import ProductVariantService from "@/services/product-variant.service";
import ProductService from "@/services/product.service";
import StoreService from "@/services/store.service";
import UserAccountActionService from "@/services/userAccountAction.service";
import { DialogTitle } from "@radix-ui/react-dialog";
import { FC, useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { firstValueFrom } from "rxjs";
import BrowserDrawer from "./BrowserDrawer";
import { ExperienceVirtualObject } from "@/models/experience-virtual-object";
import ProductInformation from "./ProductInformation";
import { ScrollArea } from "../../../components/ui/scroll-area";

const IntroVideo: FC<{
  introVideoUrl: string;
  setHasPlayedIntroVideo: (hasPlayedIntroVideo: boolean) => void;
}> = ({ introVideoUrl, setHasPlayedIntroVideo }) => {
  // when the component mounts, check to see if the user has already watched the video by looking at the value in local storage
  useEffect(() => {
    try {
      const hasPlayedIntroVideo = localStorage.getItem("hasPlayedIntroVideo");
      setHasPlayedIntroVideo(hasPlayedIntroVideo === "true");
    } catch (err) {
      console.error("Error reading from local storage", err);
    }
  }, []);

  return (
    <>
      {introVideoUrl && (
        <video
          autoPlay
          playsInline
          muted
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            zIndex: 999,
          }}
          onEnded={() => {
            setHasPlayedIntroVideo(true);
          }}
        >
          <source src={introVideoUrl} type="video/mp4" />
        </video>
      )}
    </>
  );
};

export default IntroVideo;
